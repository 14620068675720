import moment from 'moment'
import store from '../store'
import { KEYUTIL, KJUR, hextob64 } from 'jsrsasign'
import { blueColor, orangeColor, redColor, grayColor, greenColor } from '@/utils/const'

export function severityColor (severity) {
  if (severity === '紧急' || severity === 'disaster') return redColor
  else if (severity === '严重' || severity === 'high') return orangeColor
  else if (severity === '普通' || severity === 'average') return blueColor
  else return '#38D3FA'
}

export function StatusColor (severity) {
  if (severity === '异常' || severity === 'abnormal') return redColor
  else if (severity === '正常' || severity === 'normal') return greenColor
  else if (severity === '告警' || severity === 'alert') return orangeColor
  else return grayColor
}

export function humanizeTime (second, isShort = false) {
  if (!isShort) {
    if (second / 60 < 1) {
      return `${second} 秒`
    } else {
      let minute = ~~(second / 60)
      second = second - minute * 60
      if (minute / 60 < 1) {
        return `${minute} 分 ${second} 秒`
      } else {
        let hour = ~~(minute / 60)
        minute = minute - hour * 60
        if (hour / 24 < 1) {
          return `${hour} 时 ${minute} 分 ${second} 秒`
        } else {
          const day = ~~(hour / 24)
          hour = hour - day * 24
          return `${day} 天 ${hour} 时 ${minute} 分 ${second} 秒`
        }
      }
    }
  } else {
    if (second / 60 < 1) {
      return `${second} 秒`
    } else {
      let minute = ~~(second / 60)
      second = second - minute * 60
      if (minute / 60 < 1) {
        return `${minute} 分 ${second} 秒`
      } else {
        let hour = ~~(minute / 60)
        minute = minute - hour * 60
        if (hour / 24 < 1) {
          return `${hour} 时 ${minute} 分`
        } else {
          const day = ~~(hour / 24)
          hour = hour - day * 24
          return `${day} 天 ${hour} 时`
        }
      }
    }
  }
}

export function humanAlertDuration (triggeredAt, recoveredAt) {
  const r = recoveredAt ? moment(recoveredAt) : moment()
  const t = moment(triggeredAt)
  const duration = moment.duration(r.diff(t))

  let str = ''
  const day = duration.days()
  const hour = duration.hours()
  const minute = duration.minutes()
  const second = duration.seconds()

  if (day) str += `${day}天`
  if (hour) str += `${hour}时`
  if (minute) str += `${minute}分`
  if (str === '') str = `${second}秒`

  return str
}

export function humanizeValue (value) {
  if (value / 10 ** 3 < 1) {
    return `${value} `
  } else {
    value = value / 10 ** 3
    if (value / 10 ** 3 < 1) {
      return `${value.toFixed(2)} K`
    } else {
      value = value / 10 ** 3
      if (value / 10 ** 3 < 1) {
        return `${value.toFixed(2)} M`
      } else {
        value = value / 10 ** 3
        return `${value.toFixed(2)} G`
      }
    }
  }
}

export function getIntegerTicks (scale) {
  const { min, max } = scale
  if (max === 0) return [0]
  const ticks = []
  const step = Math.ceil((max - min) / 5)
  for (let i = 0; i <= max; i += step) {
    ticks.push(i)
  }
  if (ticks[ticks.length - 1] < max) {
    ticks.push(ticks[ticks.length - 1] + step)
  }
  return ticks
}

export function subTitle (title, length) {
  let len = 0
  let substr = ''
  for (let i = 0; i < title.length; i++) {
    if (len < length) {
      substr += title[i]
      if (title.charCodeAt(i) > 127 || title.charCodeAt(i) === 94) {
        len += 2
      } else {
        len++
      }
    } else {
      substr += '...'
      break
    }
  }
  return substr
}

export function updateCurrentPage (total, page = 1, pageSize = 10) {
  const lastPage = Math.ceil(total / pageSize)
  if (page === lastPage && total % pageSize === 1 && page > 1) {
    return --page
  }
  return page
}

export function encrypt (publicKey, data) {
  const key = KEYUTIL.getKey(publicKey)
  return hextob64(KJUR.crypto.Cipher.encrypt(data, key, 'RSAOAEP'))
}

export function hasPermission (currentPermissions) {
  const apiPermissions = store.getters.apiPermissions
  if (apiPermissions.findIndex(item => item.name === '全部接口') !== -1) return true
  else {
    if (apiPermissions.findIndex(item => item.key === currentPermissions) === -1) {
      return false
    }
    return true
  }
}

export function initDragColumn (columns, components) {
  const cell = (h, props, children) => {
    const { key, ...restProps } = props
    const col = columns.find(col => {
      const k = col.dataIndex || col.key
      return k === key
    })
    // canDrag: false, 禁用拖动
    if (col) {
      if (col.canDrag === false || !col.width) {
        return <th {...restProps}>{children}</th>
      }
      const onDrag = (x) => {
        col.width = Math.max(x, 1) >= 60 ? Math.max(x, 1) : 60
      }
      return <th
        {...restProps}
        width={col.width}
        class="resize-table-th"
      >
        {children}
        <vue-draggable-resizable
          key={col.key}
          class="table-draggable-handle"
          w={2}
          x={col.width}
          z={1}
          axis="x"
          dragggable={true}
          resizable={false}
          onDragging={onDrag}
        ></vue-draggable-resizable>
      </th>
    } else {
      return <th {...restProps}>{children}</th>
    }
  }
  components.header.cell = cell
}
